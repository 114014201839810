/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Chip, Grid } from "@mui/material";
import CustomTextField from "../CustomTextField";
import CustomAutocomplete from "../CustomAutocomplete";
import { useQuote } from "../../Context/Quote/Quote.context";
import { useScreenSize } from "../../hooks/useScreenSize";

const CustomAddress = ({ isQuote }) => {
  /**
   * Hook que proporciona el tamaño de pantalla actual y devuelve
   * indicadores booleanos para diferentes tamaños de dispositivos.
   *
   * @typedef {Object} ScreenSize
   * @property {boolean} isMobile - Indica si el tamaño de pantalla corresponde a un dispositivo móvil.
   * @property {boolean} isTabletVertical - Indica si el tamaño de pantalla corresponde a una tableta en orientación vertical.
   * @property {boolean} isTabletHorizontal - Indica si el tamaño de pantalla corresponde a una tableta en orientación horizontal.
   * @returns {ScreenSize} Un objeto que contiene los valores booleanos para isMobile, isTabletVertical, e isTabletHorizontal.
   */

  const { isMobile, isTabletVertical, isTabletHorizontal, isDesktop, isXXL } =
    useScreenSize();
  const [select, setSelect] = useState();

  const {
    form,
    handleChangeForm,
    handleCustomForm,
    colonies,
    patient,
    searchBranchByPostalCode,
  } = useQuote();

  const isDireccion = true;
  const direccionCP = form["direccion.cp"];
  useEffect(() => {
    // Actualizar la colonia predilecta cuando cambie el código postal
    handleCustomForm("direccion.ciudad", ""); // Reiniciar la colonia predilecta

    // Verificar si hay un código postal en el formulario
    if (form["direccion.cp"]) {
      // Obtener el valor de la ciudad seleccionada previamente
      const selectedCity = form["direccion.ciudad"];

      // Llamar a la función para buscar la sucursal por código postal y ciudad
      searchBranchByPostalCode(form["direccion.cp"], isDireccion);
    }
  }, [direccionCP, isDireccion]); // Ejecutar este efecto cuando cambie el código postal

  const handleDirecction = async (index, doc) => {
    //Funcion para verificar la sucursal
    await searchBranchByPostalCode(doc.cp, isDireccion);
    //Llenado de formulario
    handleCustomForm("direccion.calle", doc?.calle ?? "");
    handleCustomForm("direccion.numeroExt", doc?.numeroExt ?? "");
    handleCustomForm("direccion.numeroInt", doc?.numeroInt ?? "");
    handleCustomForm("direccion.referencia", doc?.referencia ?? "");
    handleCustomForm("direccion.ciudad", doc?.ciudad ?? "");
    handleCustomForm("direccion.cp", doc?.cp ?? "");
    setSelect(index);
  };

  return (
    <div id="SECTION-1">
      <Grid container>
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12}>
          <p style={design.title}>2. Dirección</p>
        </Grid>
      </Grid>
      <Grid container style={design.Card}>
        <Grid item xs={12} md={12} xl={12} sm={12} lg={12} textAlign="center">
          {patient?.Direcciones?.map((doc, index) => {
            return (
              <Chip
                label={doc.nombre}
                onClick={() => handleDirecction(index, doc)}
                style={{
                  backgroundColor: select === index ? "#00518c" : null,
                  color: select === index ? "#FFF" : "#00518c",
                  ...design.chips,
                }}
              />
            );
          })}
        </Grid>
        <CustomTextField
          label="Código postal"
          type="text"
          name="direccion.cp"
          value={form["direccion.cp"]}
          onChange={handleChangeForm}
          width={
            isMobile || isTabletVertical
              ? "90%"
              : isTabletHorizontal || isDesktop || isXXL
              ? "96%"
              : "96%"
          }
          grid={
            isMobile || isTabletVertical
              ? 12
              : isTabletHorizontal || isDesktop || isXXL
              ? 6
              : 6
          }
          required={true}
          InputLabelProps={{ shrink: true }}
        />
        <CustomAutocomplete
          onChange={(value) => handleChangeForm("direccion.ciudad", value)}
          label="Colonia"
          getOptionLabel={(option) => option}
          options={colonies}
          value={form["direccion.ciudad"]}
          width={
            isMobile || isTabletVertical
              ? "90%"
              : isTabletHorizontal || isDesktop || isXXL
              ? "96%"
              : "96%"
          }
          grid={
            isMobile || isTabletVertical
              ? 12
              : isTabletHorizontal || isDesktop || isXXL
              ? 6
              : 6
          }
        />
        <CustomTextField
          label="Delegación/Municipio"
          type="text"
          name="direccion.delegacion"
          value={form["direccion.delegacion"]}
          onChange={handleChangeForm}
          width={
            isMobile || isTabletVertical
              ? "90%"
              : isTabletHorizontal || isDesktop || isXXL
              ? "96%"
              : "96%"
          }
          grid={
            isMobile || isTabletVertical
              ? 12
              : isTabletHorizontal || isDesktop || isXXL
              ? 6
              : 6
          }
          required={false}
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Estado"
          type="text"
          name="direccion.estado"
          value={form["direccion.estado"]}
          onChange={handleChangeForm}
          width={
            isMobile || isTabletVertical
              ? "90%"
              : isTabletHorizontal || isDesktop || isXXL
              ? "96%"
              : "96%"
          }
          grid={
            isMobile || isTabletVertical
              ? 12
              : isTabletHorizontal || isDesktop || isXXL
              ? 6
              : 6
          }
          required={false}
          disabled={true}
          InputLabelProps={{ shrink: true }}
        />
        {!isQuote && (
          <>
            <CustomTextField
              label="Calle"
              type="text"
              width="98%"
              name="direccion.calle"
              value={form["direccion.calle"]}
              onChange={handleChangeForm}
              grid={12}
              required={true}
              InputLabelProps={{ shrink: true }}
            />
            <CustomTextField
              label="Num. Ext."
              type="text"
              name="direccion.numeroExt"
              value={form["direccion.numeroExt"]}
              onChange={handleChangeForm}
              grid={6}
              required={true}
              InputLabelProps={{ shrink: true }}
            />
            <CustomTextField
              label="Num. Int."
              type="text"
              width="96%"
              name="direccion.numeroInt"
              value={form["direccion.numeroInt"]}
              onChange={handleChangeForm}
              grid={6}
              required={false}
              InputLabelProps={{ shrink: true }}
            />
            <CustomTextField
              label="Referencia"
              type="text"
              name="direccion.referencia"
              value={form["direccion.referencia"]}
              onChange={handleChangeForm}
              grid={12}
              required={true}
              multiline={5}
              InputLabelProps={{ shrink: true }}
            />
          </>
        )}
      </Grid>
    </div>
  );
};

export default CustomAddress;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  chips: {
    border: "2px solid #00518c",
    borderRadius: 20,
    marginLeft: 10,
  },
};
