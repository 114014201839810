import React, { useEffect, useState } from "react";
import StripedGrid from "../../Components/StripeDataGrid";
import { Dialog, DialogContent, Stack } from "@mui/material";
import ModalDetails from "../../Components/Catalogo/ModalDetails";
import { validateToken } from "../../Apis/validateToken";
import { useAuth } from "../../Context/Auth_v2/Auth.context";
import { collection, getDocs, query } from "firebase/firestore";
import { db } from "../../firebase/firebasev2";

// ========================================================================
const ViewCatalog = () => {
  const { token, user,dataUserAccess} = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [study, setStudy] = useState({});
  const [infoFranquicia, setInfoFranquicia] = useState({})
  const [resultados, setResultados] = useState([]);
  // ======================================================================
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { branch, store } = await validateToken(token, user.uid);
        if(branch && store){
          const products = await getAllProducts(branch, store);
          setResultados(products);
          setInfoFranquicia({branch, store})
        }
      } catch (error) {
        console.error("Error fetching Data", error);
      }
    };
    fetchData();
  }, [token, user, dataUserAccess]);

  const getAllProducts = async (branch, store) => {
    try {
      const path = `Franquicias/${store}/Sucursales/${branch}/Products`;
      const ref = query(collection(db, path));
      const response = await getDocs(ref);
      const docs = response.docs.map((hit, index) => {
        return {
          available: hit.data().available,
          code: hit.data().code,
          cost: hit.data().cost,
          deliveryTime: hit.data().deliveryTime,
          departament: hit.data()?.departament || "",
          description: hit.data().description,
          fechaAlta: hit.data().fechaAlta,
          id: hit.id,
          image: hit.data().image,
          name: hit.data().name,
          np: index + 1,
          path: hit.ref.path,
          price: hit.data().price,
          recomd: hit.data().recomd,
          req: hit.data().req,
          responsable: hit.data().responsable,
          subdepartament: hit.data()?.subdepartament || "",
        };
      });
      return docs;
    } catch (error) {
      return [];
    }
  };

  const columns = [
    {
      field: "code",
      headerName: "Clave",
      width: 150,
      renderHeader: () => <p style={design.headerTable}>Clave</p>,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Nombre",
      width: 250,
      renderHeader: () => <p style={design.headerTable}>Nombre</p>,
      headerAlign: "center",
    },
    {
      field: "price",
      headerName: "Precio",
      width: 130,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Precio</p>,
      renderCell: ({ row }) => {
        return (
          <p>
            {row.price.toLocaleString("es-MX", {
              style: "currency",
              currency: "MXN",
              currencyDisplay: "symbol",
            }) || 0}
          </p>
        );
      },
    },
    {
      field: "available",
      headerName: "Disponible",
      width: 190,
      headerAlign: "center",
      renderHeader: () => <p style={design.headerTable}>Disponible</p>,
      renderCell: ({ row }) => {
        return <p>{row.available ? "Disponible" : "No disponible"}</p>;
      },
    },
  ];
 

  return (
    <>
      <Stack flex={1} direction="column">
        <h1 style={design.title}>Catálogo de servicios</h1>
        <StripedGrid
          loading={false}
          columns={columns}
          rows={resultados}
          onRowClick={(row) => {
            setOpenModal(true);
            setStudy(row.row);
          }}
          pageSize={5}
          evenBackgroundColor="#65B32E40"
          oddBackgroundColor="#efb2b2"
          oddOpacity={0.7}
          w="93%"
        />
      </Stack>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <ModalDetails 
            study={study} 
            setOpenModal={setOpenModal} 
            dataUserAccess={dataUserAccess}
            infoFranquicia={infoFranquicia}
            />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewCatalog;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    fontFamily: "Lexend",
    color: "#093046",
    fontWeight: 500,
    lineHeight: "56px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  headerTable: {
    fontSize: 16,
    color: "#00518c",
    fontFamily: "Lexend",
    justifyContent: "center",
  },
};
