/* eslint-disable react-hooks/exhaustive-deps */
/**
 * Componente de React para manejar la búsqueda con Algolia.
 * @module AlgoliaSearchComponent
 */

import React, { useEffect, useState } from "react";
import {
  Grid,
  styled,
  lighten,
  darken,
  createFilterOptions,
  Typography,
} from "@mui/material";
import algoliasearch from "algoliasearch";
import CustomAutocomplete from "../../../CustomAutocomplete";
import { useAuth } from "../../../../Context/Auth_v2/Auth.context";
import { validateToken } from "../../../../Apis/validateToken";
import { useOrder } from "../../../../Context/Ordenes_v2/Order.context";
import { useQuote } from "../../../../Context/Quote/Quote.context";

/**
 * Inicializa el cliente Algolia con las claves de API proporcionadas.
 * @type {object}
 */
const client = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APPLICATION_ID, // ID de la aplicación Algolia
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY // Clave de API de búsqueda de Algolia
);

/**
 * Componente para buscar artículos.
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.onSelect - Función para manejar la selección de un artículo.
 * @returns {JSX.Element} Componente de React para buscar artículos.
 */
const SearchArticles = ({ onSelect, refresh, isQuote}) => {
  /**
   * Hook personalizado para obtener el token de autenticación y el usuario actual.
   * @returns {{ token: string, user: object }} Objeto que contiene el token de autenticación y el usuario.
   */
  const { token, user } = useAuth();

  const contextOrder = useOrder();
  const contextQuote = useQuote();


  /**
   * Hook personalizado para obtener la sucursal seleccionada de la orden actual.
   * @returns {object} Objeto que contiene la información de la sucursal seleccionada.
   */
  const { branch: selectedBranch } = isQuote? contextQuote : contextOrder;

  /**
   * Estado local para almacenar la información de la sucursal y la tienda.
   * @type {object}
   * @property {string} branch - Nombre de la sucursal.
   * @property {string} store - Nombre de la tienda.
   */
  const [{ branch, store }, setData] = useState({ branch: "", store: "" });
  // console.log("SucursalSearchArticules: ", store);
  /**
   * Índice de búsqueda inicializado con el cliente Algolia.
   * @type {object}
   */
  const index = client.initIndex(
    `ML_PR_${store}_${selectedBranch?.id ?? branch}`
  );

  /**
   * Efecto secundario para validar el token y establecer los datos.
   */
  useEffect(() => {
    validateToken(token, user.uid).then(setData).catch(console.log);
  }, [branch]);

  /**
   * Estado local para almacenar los resultados de la búsqueda.
   * @type {array}
   */
  const [results, setResults] = useState([]);

  /**
   * Función para realizar una búsqueda en el índice de Algolia.
   * @param {string} value - Valor de búsqueda.
   * @returns {Promise<void>} Promesa resuelta después de completar la búsqueda.
   */
  const performSearch = async (value) => {
    const { hits } = await index.search(value, {
      hitsPerPage: 15,
    });
    const results = hits.map((hit) => {
      const { objectID, ...rest } = hit;
      return { id: objectID, ...rest };
    });

    setResults(results);
  };
  // console.log(results, "results");

  const items = results.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      searchParam: `${option?.code} ${option?.name}`,
      ...option,
    };
  });

  const filterOptions = createFilterOptions({
    // matchFrom: "end",
    stringify: (option) => option.searchParam,
  });
  /**
   * Manejador de cambio de evento para el componente de búsqueda.
   * @param {object} e - Evento de cambio.
   */
  const handleChange = (e) => {
    const { value } = e.target;
    !value ? setResults([]) : performSearch(e.target.value);
  };

  /**
   * Estado que controla si un componente está abierto o cerrado.
   * @type {[boolean, function(boolean): void]}
   */
  const [open, setOpen] = useState(false);

  /**
   * Efecto que realiza una búsqueda inicial cuando el componente se abre.
   *
   * @function
   * @name useEffectOpenSearch
   * @param {boolean} open - Indica si el componente está abierto.
   * @param {Function} performSearch - Función que realiza la búsqueda con un valor predeterminado.
   * @param {Function} setResults - Función que actualiza los resultados de la búsqueda.
   * @returns {void}
   */
  useEffect(() => {
    if (open) {
      // Aquí se puede hacer una búsqueda inicial con un valor vacío o predeterminado
      performSearch(""); // Llamar a la búsqueda inicial
    } else {
      setResults([]);
    }
  }, [open]);

  return (
    <CustomAutocomplete
      label="Seleccionar artículos"
      grid={12}
      width="99%"
      onChange={(value) => onSelect(value)}
      getOptionLabel={(option) => option.name}
      options={items.sort(
        (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
      )}
      filterOptions={filterOptions}
      groupBy={(option) => option.firstLetter}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loadingText="Cargando estudios"
      noOptionsText="¿No encontraste tu estudio? ¡Contáctanos y te ayudaremos!🙌🏼"
      renderGroup={(params) => (
        <li key={params.key}>
          <GroupHeader>{params.group}</GroupHeader>
          <GroupItems>{params.children}</GroupItems>
        </li>
      )}
      renderOption={(props, value) => (
        <Grid container {...props}>
          <Grid item xs={2} md={2} xl={2}>
            <Typography noWrap>
              <p style={{ fontSize: 11 }}>
                {value?.code}
                <br /> {value?.departament}
              </p>
            </Typography>
          </Grid>
          <Grid container xs={7} md={8} xl={8}>
            <Typography noWrap>
              <p style={design.Alinear}>{value?.name}</p>
            </Typography>
          </Grid>
          <Grid item xs={3} md={2} xl={2}>
            <Typography noWrap>
              <p style={design.price}>
                {value?.price?.toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  currencyDisplay: "symbol",
                })}{" "}
              </p>
            </Typography>
          </Grid>
        </Grid>
      )}
      onTextChange={handleChange}
    />
  );
};
export default SearchArticles;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  margin: { marginLeft: "1%", marginRight: "1%" },
  txt: { fontWeight: "bold" },
  price: {
    color: "#356c2d",
    fontWeight: "bold",
    textAlign: "right",
    fontSize: 14,
  },
  Alinear: {
    alignItems: "center",
    whiteSpace: "pre-wrap",
    fontWeight: "bold",
    paddingLeft: 10,
  },
};

const GroupHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  padding: "4px 10px",
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === "light"
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled("ul")({
  padding: 0,
});
