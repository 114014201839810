import React, { useState } from "react";
import { Grid } from "@mui/material";
import { useDimensions } from "../../hooks/useDimensions";
import CustomData from "../../Components/Cotizaciones/CustomData";
import CustomAddress from "../../Components/Cotizaciones/CustomAddress";
import CustomStudies from "../../Components/Ordenes/V2/CustomStudies";
import Modal from "../../Components/Modal";
import { useQuote } from "../../Context/Quote/Quote.context";
import CustomPayment from "../../Components/Ordenes/V2/CustomPayment";
import TicketOrden from "../../Components/Ordenes/V2/TicketOrden";
import { Edit } from "@mui/icons-material";

const CreateQuote = () => {
  const [typeCreate, setTypeCreate] = useState(false);
  const [openModal, setOpenModal] = useState(true);
  const { branch, selectBranch } = useQuote();

  const dimensionHeight = useDimensions("height");
  const dimensionWidth = useDimensions("width");
  const widthTablet = dimensionWidth > 768 && dimensionWidth < 1023;
  const widthTelefono = dimensionWidth > 320 && dimensionWidth < 767;
  const Ticket = widthTablet > widthTelefono;

  const style = {
    height: `${dimensionHeight}` - 155,
    overflow: "auto",
    paddingLeft: 15,
  };

  const handleOpenModel = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleClick = () => {
    if (selectBranch) handleOpenModel();
  };

  return (
    <>
      <Grid container backgroundColor="#D9EDFF" className="C4">
        <Grid xs={12} md={6} xl={7} lg={7} style={design.ColorFondo}>
          <p style={design.title}>Crea una cotización</p>
          <p style={design.textCreate}>
            {branch?.name || null}{" "}
            <Edit onClick={handleClick} style={design.Edit} />
          </p>
          <Grid xs={12} sm={12} md={12} lg={12} xl={12} style={style}>
            <CustomData />
            <CustomAddress isQuote={true} />
            <CustomStudies isQuote={true} />
            <CustomPayment isQuote={true} />
          </Grid>
        </Grid>
        <Grid xs={Ticket ? 5 : 12} sm={12} md={6} lg={5} xl={5}>
          <TicketOrden isQuote={true} />
        </Grid>
      </Grid>
      <Modal.StartQuotes
        open={openModal}
        handleClose={handleCloseModal}
        typeCreate={typeCreate}
        setTypeCreate={setTypeCreate}
        onSelect={(value) => {
          selectBranch(value);
          handleCloseModal();
        }}
      />
    </>
  );
};
export default CreateQuote;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "center",
    fontWeight: "500",
    color: "#00518c",
    fontSize: 27,
    marginBottom: 2,
  },
  textEdit: {
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  textCreate: {
    fontSize: 17,
    textAlign: "center",
    marginRight: "2%",
    marginTop: "-0.5%",
    fontWeight: "600",
    color: "#4496D2",
    fontFamily: "Comic Sans MS, Comic Sans, cursive",
  },
  Edit: {
    fontSize: 17,
    color: "#4496D2",
    marginTop: 5,
  },
  ColorFondo: {
    padding: "0px 5px",
    backgroundColor: "#FFF",
    borderTopRightRadius: 40,
  },
};
