/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { Grid, TableCell, TableRow, Tooltip } from "@mui/material";
import { Add, Info, Remove } from "@mui/icons-material";
import Modal from "../../Modal";
import { useProduct } from "../../../hooks/Products/useProduct";

const ItemCrear = ({
  id,
  quantity,
  descuento,
  label,
  unitprice,
  franquicia,
  sucursal,
  subtotal,
  botomDisable,
  all,
  idx,
  onProductCountChange,
}) => {
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const { increaseBy, decrementBy } = useProduct({});
  const handleChange = () => {
    const results = increaseBy(+1, descuento, all, idx);
    results.map((doc) => {
      delete doc.counter;
      onProductCountChange(1, doc);
    });
  };
  const handleChangeRemove = () => {
    const results = decrementBy(1, all, idx);
    results.map((doc) => {
      delete doc.count;
      onProductCountChange(doc.cantidad, doc);
    });
  };

  return (
    <>
      <TableRow key={id}>
        <TableCell style={{ ...design.row, maxWidth: '200px' }}>
          <Grid container direction="row" alignItems="left" sx={{ height: 40 }}>
            {/*  details, name studie & unitprice */}
            {info && (
              <Info
                style={design.IconInfo}
                fontSize="small"
                onClick={handleClick}
              />
            )}
            <Tooltip arrow title={label}>
              <p style={design.label}>
                {label}
                <p style={design.unitprice}>
                  {unitprice.toLocaleString("es-MX", {
                    style: "currency",
                    currency: "MXN",
                    currencyDisplay: "symbol",
                  })}
                </p>
              </p>
            </Tooltip>
          </Grid>
        </TableCell>
        <TableCell style={design.row}>
          <Grid
            container
            direction={botomDisable === true ? "column" : "row"}
            justifyContent="space-around"
            alignItems="center"
          >
            {!botomDisable && (
              <Remove
                fontSize="small"
                style={{ color: "#00518c", fontSize: 14 }}
                onClick={() => handleChangeRemove()}
              />
            )}
            <p style={{ color: "#00518c", fontSize: 12 }}>{quantity}</p>
            {!botomDisable && (
              <Add
                fontSize="small"
                style={{ color: "#00518c", fontSize: 14 }}
                onClick={() => handleChange()}
              />
            )}
          </Grid>
        </TableCell>
        <TableCell align="center" style={design.row}>
          {descuento}%
        </TableCell>
        <TableCell align="center" style={design.sub}>
          {subtotal.toLocaleString("es-MX", {
            style: "currency",
            currency: "MXN",
            currencyDisplay: "symbol",
          })}
        </TableCell>
      </TableRow>

      {/* Modal con detalles */}
      <Modal.Details
        open={open}
        handleClose={handleClick}
        id={id}
        setInfo={setInfo}
        franquicia={franquicia}
        sucursal={sucursal}
      />
    </>
  );
};
export default ItemCrear;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  label: {
    color: "rgba(0, 81, 140, 1)",
    marginLeft: 5,
    width:'95%',
    whiteSpace: "nowrap", // Evita saltos de línea
    overflow: "hidden", // Oculta el contenido que no cabe
    textOverflow: "ellipsis", // Muestra puntos suspensivos (...)
    lineHeight: "80%",
    maxWidth: "90%", 
    fontSize: 12
  },
  unitprice: {
    textAlign: "left",
    fontWeight: "400",
    color: "#4496D2",
    fontSize: 10,
    whiteSpace: "nowrap", // Evita saltos de línea
    overflow: "hidden", // Oculta el contenido que no cabe
    textOverflow: "ellipsis", // Muestra puntos suspensivos (...)
  },
  IconInfo: { color: "rgba(0, 81, 140, 1)", marginTop: 8, fontSize: 12 },
  row: {
    borderRight: "2px dashed rgba(0, 81, 140, 0.4)",
    fontFamily: "Lexend",
    color: "#093046",
    borderBottom: "none",
    // borderBottom: "2px dashed rgba(0, 81, 140, 0.4)",
    fontSize: 12,
  },
  sub: {
    border: "none",
    fontFamily: "Lexend",
    color: "#093046",
    fontSize: 12,
    // borderBottom: "2px dashed rgba(0, 81, 140, 0.4)",
  },
  textContainer: {
    display: "flex", // Para mostrar el texto en línea
    justifyContent: "space-between", // Alinea los elementos dentro de la misma línea
    width: "100%", // Asegura que el contenido ocupe todo el espacio disponible
  },
};
