/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/**
 * Componente para gestionar el horario de servicio en un formulario de pedido.
 * @module CustomServiceHours
 */
import React, { useState, useEffect } from "react";
import { Avatar, Alert, Chip, CircularProgress, Grid } from "@mui/material";
import { LocalizationProvider, DateCalendar } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSnackbar } from "notistack";
import firebase from "../../../firebase";
import { useHours } from "../../../hooks/useHorarios";
import { useOrder } from "../../../Context/Ordenes_v2/Order.context";
import { validateToken } from "../../../Apis/validateToken";
import { useAuth } from "../../../Context/Auth_v2/Auth.context";
import { useDimensions } from "../../../hooks/useDimensions";
import { getHoursOrders } from "../../../Utils/getHoursOrders";
import { useQuote } from "../../../Context/Quote/Quote.context";

const imagen = {
  laboratorio:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Laboratorio.png",
  radiologia:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Rayos_X.png",
  operador:
    "https://movicaremx.com/IMG/app_mobile/FormatPNG/Services/Ambulancia.png",
};
const CustomServiceHours = ({ isQuote }) => {
  const { hours, loading, setSelectedDate } = useHours();
  const contextOrder = useOrder();
  const contextQuote = useQuote();
  const { tags, setHorarios, branch } = isQuote ? contextQuote : contextOrder;
  const { token, user } = useAuth();
  const activity = !isQuote ? contextOrder.activity : undefined;

  return (
    <div id="SECTION-4" style={design.margin}>
      <p style={design.title}>5. Horario de servicio</p>
      {!tags.length ? (
        <Alert severity="warning" variant="filled" style={design.alert}>
          Para obtener la visualización de los horarios disponibles por área de
          servicio, es necesario añadir un estudio
        </Alert>
      ) : null}
      {/* Contenedor principal del componente */}
      {tags.join("|").includes("SERVICIOS") ||
      tags.join("|").includes("TOM") ||
      tags.join("|").includes("RES") ? (
        <>
          <Horarios
            imagen={imagen.operador}
            setSelectedDate={setSelectedDate}
            loading={loading}
            token={token}
            user={user}
            branch={branch}
            tags={tags}
            hours={hours}
            activity={activity}
            setHorarios={setHorarios}
            area={"operador"}
            isQuote={isQuote}
          />
        </>
      ) : null}

      {tags.join("|").includes("LABORATORIO") ||
      tags.join("|").includes("ESTUDIOS") ? (
        <Horarios
          imagen={imagen.laboratorio} // imagen title
          setSelectedDate={setSelectedDate} // el dia seleccionado (azul)
          loading={loading} //spinner de carga customchip
          token={token} // token del usuario
          user={user} //data del usuario
          branch={branch} // sucursal del usuario
          tags={tags} // estudios añadidos (SOLO DEBE SER LAB)
          hours={hours} //HORAS COMPLETAS (7-15)
          activity={activity} //STEPPER PINT
          setHorarios={setHorarios} // LLENAR EL DIA A SELECCIONAR EN BD
          area={"laboratorio"} // TAG DEBE SER SOLO LAB
          isQuote={isQuote}
        />
      ) : null}

      {tags.join("|").includes("RAD") ? (
        <>
          <Horarios
            imagen={imagen.radiologia}
            setSelectedDate={setSelectedDate}
            loading={loading}
            token={token}
            user={user}
            branch={branch}
            tags={tags}
            hours={hours}
            activity={activity}
            setHorarios={setHorarios}
            area={"radiologia"}
            isQuote={isQuote}
          />
        </>
      ) : null}
    </div>
  );
};

const Horarios = ({
  imagen,
  setSelectedDate,
  loading,
  token,
  user,
  branch,
  tags,
  activity,
  setHorarios,
  area,
  hours,
  isQuote,
}) => {
  const TYPES = {
    laboratorio: "Laboratorio",
    radiologia: "Radiología",
    operador: "Operador",
  };

  const [hoursSelect, setHoursSelect] = useState([]);
  const [orders, setOrders] = useState([]);
  const [chipSelected, setChipSelected] = useState(false);

  // Estado local para almacenar el día seleccionado
  const [selectedDay, setSelectedDay] = useState({
    day: new Date().getDate(),
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
  });

  //Renderizar en pantalla
  const { day, month, year } = selectedDay;
  /**
   * Hook para mostrar notificaciones con el Snackbar de Material-UI.
   * @see https://mui.com/components/snackbars/
   */
  const { enqueueSnackbar } = useSnackbar();
  const [newOrdersHours, setNewOrdersHours] = useState([]);

  useEffect(() => {
    const current_date = new Date();
    const typeMayus = area.toUpperCase();
    let type = buscarCoincidencias(tags, typeMayus);
    if (type != null) handleDateChange({ $d: current_date }, area);
  }, [tags]);

  const buscarCoincidencias = (tags, area) => {
    let coincidencias = [];
    for (let i = 0; i < tags.length; i++) {
      if (tags[i] === area) {
        coincidencias.push(tags[i]);
      }
    }
    return coincidencias;
  };

  const handleDateChange = async (newDate) => {
    //Desestructura la fecha
    const day = newDate.$d.getDate();
    const month = newDate.$d.getMonth();
    const year = newDate.$d.getFullYear();

    const _selectedDate = new Date(year, month, day);
    //envia fecha seleccionada al hook
    setSelectedDate(_selectedDate);

    //Renderiza en pantalla la fecha seleccionada
    setSelectedDay({
      day: newDate.$d.getDate(),
      month: newDate.$d.getMonth() + 1,
      year: newDate.$d.getFullYear(),
    });
    //Busca las ordenes del dia seleccionado por el area del servicio
    const servicesHours = await getServices(_selectedDate, area);
    //Obtiene los horarios de las ordenes del dia por el area del servicio
    await getHoursServices(servicesHours, area);
  };

  //Paso 1
  //Función para buscar ordenes del dia
  const getServices = async (date, area) => {
    //fecha inicial del dia
    const fechaSinMinutos = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0
    );

    //Fecha final del dia
    const endDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      23,
      59,
      59
    );

    //Verifica el inicio de sesión
    const responseFranquicia = await validateToken(token, user?.uid);
    //query: Busca las ordenes dependiendo la franquicia y la sucursal obteniendo
    //todos los pedidos por el dia seleccionado
    const response = await firebase.db
      .collection("PedidosAux")
      .where(`services_Dates.${area}`, ">=", fechaSinMinutos)
      .where(`services_Dates.${area}`, "<=", endDate)
      .where("franquicia", "==", responseFranquicia.store)
      .where("sucursal", "==", branch.id)
      .get();

    const arr = response.docs.map((doc) => {
      const orden = {
        ...doc.data(),
        ref: doc.ref,
        id: doc.id,
      };
      return orden;
    });

    setOrders(arr.filter(Boolean));
    //devuelve el arreglo de todos los pedidos existentes
    return arr;
  };

  //Paso 2.
  //Función para ontener las horas de los pedidos
  const getHoursServices = async (servicesHours, type) => {
    if (servicesHours.length === 0) {
      return;
    }
    // Filtra los elementos de servicesHours para incluir
    // solo aquellos objetos que tienen una propiedad services_Dates
    //
    const newFechas = servicesHours
      .filter((objeto) => objeto.services_Dates !== undefined)
      .map((objeto) => {
        const horario = getHoursOrders(objeto.services_Dates?.[type]);

        return horario !== null ? horario : null;
      });

    if (type === "laboratorio" && newFechas.length) {
      setNewOrdersHours(newFechas);
    } else if (type === "radiologia" && newFechas.length) {
      setNewOrdersHours(newFechas);
    } else if (type === "operador" && newFechas.length) {
      setNewOrdersHours(newFechas);
    }
  };

  // definitivo 500000
  useEffect(() => {
    let hoursData = [];

    if (newOrdersHours.length > 0) {
      hoursData = hours.map((element) => {
        const totalServices = newOrdersHours.filter((o) => o === element.hour);
        return {
          ...element,
          services: element.services + totalServices.length,
        };
      });

      setHoursSelect(hoursData);
      return;
    }
    setHoursSelect(hours);
  }, [hours, newOrdersHours]);

  //Colorea los horarios seleccionados/no_Disponible & Predeterminado
  const getBackgroundColor = (doc, idx) => {
    if (chipSelected === idx) {
      return "#D9EDFF"; // Color cuando está seleccionado
    } else if (
      (branch.name === "Texcoco" && doc.services >= 2) ||
      (branch.name !== "Texcoco" && doc.services >= 1)
    ) {
      return "#FFADADCC"; // Color cuando no está disponible
    } else {
      return "#78BC4766"; // Color predeterminado
    }
  };
  const dimensionWidth = useDimensions("width");
  const tam = dimensionWidth < 1500;

  return (
    <>
      <Grid container style={design.Card}>
        {/* Calendario para seleccionar el día */}
        <Grid container alignItems="center">
          <Avatar src={imagen} />
          <p style={design.txt}>{TYPES[area]}</p>
        </Grid>
        <Grid item xs={12} md={12} xl={4} lg={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              disablePast={true}
              onChange={(date) => handleDateChange(date, area)}
            />
          </LocalizationProvider>
        </Grid>
        {/* Espaciador */}
        <Grid item xs={12} md={2} xl={0} lg={tam ? 0 : 1} />
        {/* Contenedor de horarios */}
        <Grid item xs={12} md={12} xl={7} lg={tam ? 6 : 5} style={design.hours}>
          {/* Mostrar la fecha seleccionada */}
          <h3 style={design.fecha}>
            {`${day < 10 ? "0" + day : day} - ${
              month < 10 ? "0" + month : month
            } - ${year}`}
          </h3>
          {/* Mostrar los chips de horarios */}
          <Grid container>
            {loading ? (
              <Grid
                xs={12}
                md={12}
                xl={12}
                sm={12}
                lg={12}
                style={design.Spiner}
              >
                <CircularProgress />
              </Grid>
            ) : (
              hoursSelect.map((doc, idx) => {
                /**
                 * Separa la hora y los minutos de una cadena y los convierte en enteros.
                 *
                 * @param {Object} doc - El documento que contiene la hora en formato `HH:mm`.
                 * @returns {Array} Un array que contiene la hora y los minutos como enteros.
                 */
                const [hour, minutes] = doc.hour.split(":");

                /**
                 * Convierte la hora y los minutos a enteros.
                 *
                 * @type {number}
                 */
                const startHour = parseInt(hour, 10);

                /**
                 * Calcula la hora de finalización sumando 1 hora a la hora de inicio.
                 *
                 * @type {number}
                 */
                const endHour = startHour + 1;

                /**
                 * Obtiene la hora actual utilizando una función personalizada `getHoursOrders`.
                 *
                 * @param {Date} date - La fecha actual.
                 * @returns {number} La hora actual basada en la fecha.
                 */
                const hourNow = getHoursOrders(new Date());

                /**
                 * Crea un objeto Date que representa la fecha y hora actual.
                 *
                 * @type {Date}
                 */
                const dateNow = new Date();

                /**
                 * Obtiene el día del mes actual.
                 *
                 * @type {number}
                 */
                const dayNow = dateNow.getDate();

                console.log("hourNow", hourNow);
                console.log("dayNow", dayNow);
                console.log("branch.limit_time_prev", branch.limit_time_prev);
            
                // Convertir la hora actual y la hora límite en minutos
                const [currentHours, currentMinutes] = hourNow
                  .split(":")
                  .map(Number);
                const currentTotalMinutes = currentHours * 60 + currentMinutes;

                const [startHours, startMinutes] = doc.hour
                  .split(":")
                  .map(Number);
                const startTotalMinutes = startHours * 60 + startMinutes;

                const limitTime = startTotalMinutes - branch.limit_time_prev;

                // Deshabilitar si la hora actual es mayor o igual a la hora límite
                const isDisabled = currentTotalMinutes >= limitTime;
                // Deshabilitar algun tipo de servicio especifico y horarios especificos
                const isToday =
                day === dateNow.getDate() &&
                month === dateNow.getMonth() + 1 &&
                year === dateNow.getFullYear();
            
                // const isDisabledRAD = isToday && area === "radiologia" && startHour >= 8 && startHour < 12;
                // const isDisabledLAB = isToday && area === "laboratorio" && startHour >= 9 && startHour < 12;
                // const isDisabledOPE = isToday && area === "operador" && startHour >= 9 && startHour < 12;
                return (
                  <Grid key={idx} item xs={6} md={6} xl={6} sm={6} lg={6}>
                    {/* Chip para mostrar el horario */}
                    <Chip
                      key={idx}
                      clickable
                      disabled={
                        branch.name === "Texcoco" && doc.services >= 2
                          ? true
                          : branch.name !== "Texcoco" && doc.services >= 1
                          ? true
                          : dayNow === day &&
                            parseInt(hourNow) > parseInt(doc.hour)
                          ? true
                          : dayNow === day && isDisabled
                          ? true 
                          : false
                          // : isDisabledRAD ? true :false
                      }
                      style={{
                        backgroundColor: getBackgroundColor(doc, idx),
                        ...design.chip,
                      }}
                      label={`${startHour}:${minutes} - ${endHour}:${minutes}`}
                      onClick={() => {
                        setChipSelected(idx);
                        setHorarios((e) => ({
                          ...e,
                          [area]: doc.date,
                        }));
                        if (!isQuote) {
                          activity.push(4);
                        }

                        if (branch.name === "Texcoco" && doc.services >= 2) {
                          enqueueSnackbar(
                            `Horario no disponible, favor de elegir un nuevo horario Texcoco`,
                            {
                              variant: "warning",
                              preventDuplicate: true,
                              autoHideDuration: 1600,
                            }
                          );
                        } else if (
                          branch.name !== "Texcoco" &&
                          doc.services >= 1
                        ) {
                          enqueueSnackbar(
                            `Horario no disponible, favor de elegir un nuevo horario otras`,
                            {
                              variant: "warning",
                              preventDuplicate: true,
                              autoHideDuration: 1600,
                            }
                          );
                        } else {
                          enqueueSnackbar(
                            `Servicio seleccionado para ${area}`,
                            {
                              variant: "success",
                              preventDuplicate: true,
                              autoHideDuration: 1600,
                            }
                          );
                        }
                      }}
                    />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomServiceHours;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "99%",
    padding: 15,
    marginTop: 25,
  },
  alert: {
    width: "91%",
    fontSize: 12,
    marginTop: "2%",
    marginBottom: "2%",
    borderRadius: 15,
  },
  fecha: {
    textAlign: "center",
    fontWeight: "600",
    color: "#4496D2",
  },
  chip: {
    margin: 5,
    width: "90%",
    fontSize: 15,
    borderRadius: 15,
  },
  margin: { marginBottom: "20%" },
  txt: {
    textAlign: "left",
    fontWeight: "600",
    color: "#093046",
    fontSize: 19,
  },
  Spiner: {
    textAlign: "center",
    paddingTop: "15%",
  },
  hours: {
    borderRadius: 20,
    width: "99%",
  },
};
