/**
 * Componente funcional para mostrar y manejar los datos personalizados del cliente.
 * @module CustomData
 * @returns {JSX.Element} Elemento de React que muestra los datos personalizados.
 */
import React, { useState } from "react";
import { Grid, Stack } from "@mui/material";
import CustomTextField from "../../CustomTextField";
import { useOrder } from "../../../Context/Ordenes_v2/Order.context";
import SearchUser from "../../SearchUser";
import Modal from "../../Modal";
import { AnimatedBox } from "../../../CSS/AnimatedBox";
import { useDimensions } from "../../../hooks/useDimensions";

/**
 * Componente funcional que muestra y maneja los datos personalizados del cliente.
 * @returns {JSX.Element} Elementode React que muestra los datos personalizados.
 */
const CustomData = () => {
  const {
    form,
    handleChangeForm,
    handleCustomForm,
    activity,
    patient,
    imagePromotion,
    imageDiscounts,
    imageDiscountsPublic,
  } = useOrder();
  const dimensionWidth = useDimensions("width");
  const Tam = dimensionWidth < 1000;

  /**
   * Manejador de selección de usuario.
   * @param {object} data - Datos del usuario seleccionado.
   * @param {object} data.DatosPersonales - Información personal del usuario.
   * @param {string} data.path - Ruta del usuario seleccionado.
   */
  const handleSelect = ({ DatosPersonales, path, refUsuario }) => {
    const { nombre, correo, telefono } = DatosPersonales;

    handleCustomForm("phone", telefono ?? "");
    handleCustomForm("email", correo ?? "");
    handleCustomForm("name", nombre ?? "");
    handleCustomForm("refUsuario", refUsuario ?? "");
    handleCustomForm("clientPath", path ?? "");
    activity.push(0);
  };

  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div id="SECTION-0">
      <p style={design.title}>1. Datos de contacto</p>
      <Grid container style={design.Card}>
        <SearchUser
          onSelect={handleSelect}
          onChange={(value) => handleCustomForm("name", value)}
          Tam={Tam}
        />
        <CustomTextField
          label="Teléfono"
          type="phone"
          value={form.phone}
          grid={6}
          required={true}
          name="phone"
          onChange={handleChangeForm}
          maxLength={10}
          InputLabelProps={{ shrink: true }}
        />
        <CustomTextField
          label="Correo electrónico"
          type="email"
          value={form.email}
          width="96%"
          grid={6}
          required={false}
          name="email"
          onChange={handleChangeForm}
          InputLabelProps={{ shrink: true }}
        />
        <Stack
          direction={!Tam ? "row" : "column"}
          ml={Tam ? 7 : 3}
          spacing={Tam ? 1 : 9}
          alignItems="center"
          justifyContent="space-between"
        >
          {info && (
            <AnimatedBox
              component="img"
              alt="Descuentos"
              src="https://movicaremx.com/IMG/app_mobile/FormatPNG/Descuento.png"
              m="0% 2%"
              // 500 x 500
              width={50}
              height={50}
              onClick={handleClick}
            />
          )}
          <p style={design.info}>
            Número de servicios: {patient?.numberOfServices}
          </p>
          <p style={design.info}>
            Fecha del primer servicio: {patient?.firstService}
          </p>
          <p style={design.info}>
            Fecha del último servicio: {patient?.lastService}
          </p>
        </Stack>
      </Grid>
      <Modal.Discounts
        open={open}
        handleClose={handleClick}
        setInfo={setInfo}
        promotionOfTheMonth={imagePromotion}
        imageDiscounts={imageDiscounts}
        imageDiscountsPublic={imageDiscountsPublic}
      />
    </div>
  );
};

export default CustomData;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  title: {
    textAlign: "left",
    fontWeight: "600",
    color: "#4496D2",
    fontSize: 23,
    lineHeight: "20px",
  },
  Card: {
    border: "2px solid #C6C6C6",
    borderRadius: 20,
    width: "95%",
    padding: 15,
  },
  info: {
    textAlign: "center",
    color: "#4b818b",
    fontWeight: "600",
  },
};
