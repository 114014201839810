import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import Item from "./Item";
import { useDimensions } from "../../../hooks/useDimensions";

/**
 * CustomTable component renders a table displaying order details such as studies, quantities, discounts, and subtotal.
 * @param {object} props - Component props.
 * @param {boolean} props.botomDisable - Flag indicating whether to disable certain functionality.
 * @param {object} [props.order={}] - Order details object.
 * @returns {JSX.Element} React component.
 */
const CustomTable = ({ botomDisable, order = {} }) => {
  const [estudios, setEstudios] = useState([]);
  const dimensionWidth = useDimensions("width");
  const Phone = dimensionWidth < 1000;
  // Function to retrieve the list of studies
  const getEstudios = (orden) => {
    // Logic to determine which types of studies to show in the table
    // (X-rays, Laboratory, both, or neither type)
    if (orden.RayosX && !orden.Laboratorio) {
      setEstudios(orden?.RayosX);
    } else if (orden.Laboratorio && !orden.RayosX) {
      setEstudios(orden?.Laboratorio);
    } else if (orden.Laboratorio && orden.RayosX) {
      const pedido = orden?.Laboratorio.concat(orden?.RayosX);
      setEstudios(pedido);
    } else if (!orden.Laboratorio && !orden.RayosX) {
      setEstudios(orden.Orden);
    }
  };
  const style = {
    height: 400,
    overflow: "auto",
  };
  useEffect(() => {
    getEstudios(order);
  }, [order]);

  return (
    <>
      <Grid style={Phone ? null : style}>
        <TableContainer style={design.Table}>
          <Table stickyHeader size="small">
            {/* Table headers */}
            <TableHead>
              <TableRow>
                <TableCell style={{ ...design.cell, ...design.desc }}>
                  Estudio
                </TableCell>
                <TableCell style={{ ...design.cell, ...design.desc }}>
                  Cantidad
                </TableCell>
                <TableCell style={{ ...design.cell, ...design.desc }}>
                  Desct.
                </TableCell>
                <TableCell style={{ ...design.cell }}>Subtotal</TableCell>
              </TableRow>
            </TableHead>
            {/* Table body */}
            <TableBody>
              {estudios?.map((doc) => (
                <Item
                  key={doc.id}
                  subtotal={doc.total}
                  quantity={doc.cantidad}
                  descuento={doc.descuento}
                  label={doc.nombre}
                  unitprice={doc.precio}
                  franquicia={order.franquicia}
                  sucursal={order.sucursal}
                  botomDisable={botomDisable}
                  id={doc.id}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Table>
        {/* Summaries */}
        <TableRow>
          <TableCell colSpan={4} />
          <TableCell colSpan={4} />
          <TableCell colSpan={4} />
          <TableCell align="right" style={design.sub}>          
            Subtotal <br /> {order.descuento > 0 && "Descuento"} <br />
            Total <br /> <br />
            Método de pago <br />      
          </TableCell>
          <TableCell align="left">
            {order?.subtotal
              ? order?.subtotal?.toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  currencyDisplay: "symbol",
                })
              : "$0.00"}
            <br />
            {order.descuento > 0 && <>{order.descuento} %</>} <br />
            {order?.total
              ? order?.total?.toLocaleString("es-MX", {
                  style: "currency",
                  currency: "MXN",
                  currencyDisplay: "symbol",
                })
              : "$0.00"}
              <br />
              <br />
              {order?.metodoDePago}
            <br />          
          </TableCell>
        </TableRow>
      </Table>
    </>
  );
};

export default CustomTable;

/**
 * design es un objeto que contiene estilos personalizados.
 * @type {Object}
 */
const design = {
  Table: {
    borderBottom: "2px dashed rgba(0, 81, 140, 0.4)",
    borderTop: "2px dashed rgba(0, 81, 140, 0.4)",
    // minHeight: 300,
    // width: "98%",
  },
  cell: {
    backgroundColor: "#D9EDFF",
    textAlign: "center",
    fontWeight: "500",
    color: "#093046",
    fontSize: 12,
    fontFamily: "Lexend",
    borderBottom: "2px dashed rgba(0, 81, 140, 0.4)",
  },
  desc: {
    borderRight: "2px dashed rgba(0, 81, 140, 0.4)",
  },
  sub: {
    fontFamily: "Lexend",
    color: "#093046",
    fontSize: 15,
    textAlign: "right",
  },
};
